<template>
  <b-navbar
    id="pdf-header"
    :style="pdfPageHeaderStyle"
    style="z-index: -4000"
    class="d-print-block w-100 p-0"
    toggleable="sm"
    type="dark"
    variant="primary"
  >
    <img
      v-if="pdfHeaderImgSrc"
      :src="pdfHeaderImgSrc"
      style="height: 100%; width: 100%"
      class="pdf-size-override"
    />
    <div
      class="position-absolute w-100"
      style="height: inherit; z-index: 1; top: 0"
    >
      <div
        :style="textStyle"
        class="w-100 pt-5 pl-4pt5"
      >
        <div class="d-flex flex-column align-items-start">
          <img
            v-if="customLogo"
            :src="customLogo"
            height="40"
            class="mb-2"
          />
          <span
            v-if="title"
            class="font-weight-500"
            :style="titleStyle"
          >
            {{ title }}
          </span>
          <span
            v-if="subtitle"
            :style="subtitleStyle"
          >
            {{ subtitle }}
          </span>
        </div>
      </div>
    </div>
  </b-navbar>
</template>

<script lang="ts">
import { SettingsModule } from '@/store/modules/settings';
import { UserModule } from '@/store/modules/user';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'WhitelabelPdfHeader',
  setup() {
    const title = computed(() => {
      return SettingsModule.printTitle;
    });

    const subtitle = computed(() => {
      return SettingsModule.printSubtitle;
    });

    const user = computed(() => {
      return UserModule.user;
    });

    /**
     * Default font weight in header is 500 because it's in a <thead>
     * element (so that it automatically repeats across pages). So we
     * set it back to 400.
     */
    const pdfPageHeaderStyle = computed(() => {
      return {
        'font-weight': 400,
        ...SettingsModule.pdfPageHeaderStyle,
      };
    });

    const textStyle = computed(() => {
      return SettingsModule.pdfPageHeaderTextStyle;
    });

    const titleStyle = computed(() => {
      return SettingsModule.pdfHeaderTitleStyle;
    });

    const subtitleStyle = computed(() => {
      return SettingsModule.pdfHeaderSubtitleStyle;
    });

    const customLogo = computed(() => {
      if (SettingsModule.pdfPageHeaderCustomDynamicLogo) {
        return SettingsModule.pdfPageHeaderCustomDynamicLogo;
      }
      if (!user.value || !user.value.logoImage) return '';
      return user.value.logoImage;
    });

    const pdfHeaderImgSrc = computed(() => {
      return SettingsModule.pdfHeaderImgSrc;
    });

    return {
      title,
      subtitle,
      user,
      pdfPageHeaderStyle,
      textStyle,
      titleStyle,
      subtitleStyle,
      customLogo,
      pdfHeaderImgSrc,
    };
  },
});
</script>
